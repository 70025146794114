.css-scroll {scrollbar-color: $dark $gray-300; scrollbar-width: thin; overflow-y: auto !important;
	&::-webkit-scrollbar {width: 8px; background-color: $gray-300;} 
	&::-webkit-scrollbar-thumb {background-color: $dark; border-radius:10px;}
	&::-webkit-scrollbar-thumb:hover {background-color: $dark; border:1px solid $dark;}
	&::-webkit-scrollbar-thumb:active {background-color: $dark; border:1px solid $dark;} 
}
// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   box-shadow: 0 0 0px 1000px $gray-600 inset;
//   -webkit-text-fill-color: green;
//   transition: background-color 5000s ease-in-out 0s;
// }
.custom-select {background: $white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%239198A8' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;}

.form-control {height: 50px;}

.padding-5 {margin-left: -5px; margin-right: -5px;
	[class*="col"] {padding-left: 5px; padding-right: 5px;}
}

.lazy-load-image-background {width: 100% !important;}
.minH-250 {@media (min-width: 600px) {min-height: 250px;}}
.embed-responsive-21by9:before {padding-top: 100%;}
.share-button {text-align: left;}

.body-bg {background-color: $body-bg !important;}

.MuiSkeleton-root {background-color: rgba($white, 0.21) !important;}
.btn-custom {color: $white; transition: all 0.3s ease-in-out;
	&:focus {outline: none; box-shadow: none;}
	&.btn-lg {width: 276px; height: 61px; text-transform: uppercase; font-size: 16px; font-weight: 500; padding: 18px 30px; background: url("/images/btn-lg.png") no-repeat;
		&:hover {color: $white; background: url("/images/btn-lg-hover.png") no-repeat;}
	}
	&.btn-md {width: 208px; height: 53px; text-transform: uppercase; font-size: 16px; font-weight: 500; padding: 14px 30px; background: url("/images/btn-md.png") no-repeat;
		&:hover {color: $white; background: url("/images/btn-md-hover.png") no-repeat;}
	}
	&.btn-sm {width: 115px; height: 33px; text-transform: uppercase; font-size: 14px; font-weight: 500; background: url("/images/btn-sm.png") no-repeat;
		&:hover {color: $white; background: url("/images/btn-sm-hover.png") no-repeat;}
	}
	&.btn-invite {background: url("/images/btn-invite.png") no-repeat;width: 230px; height: 33px; text-transform: uppercase; font-size: 14px; font-weight: 700;color:$black;text-align:center;
		&:hover {color: $black; background: url("/images/btn-invite.png") no-repeat;}
	}
	&.profile-btn {background: url("/images/profile-btn.png") no-repeat; width: 230px; height: 50px; text-transform: uppercase; font-size: 14px; font-weight: 700;color:$white;text-align:center;padding: 16px 10px 2px;
		&:hover {color: $white; background: url("/images/profile-btn.png") no-repeat;}
	}
	&.btn-leave {background: url("/images/btn-leave.png") no-repeat;width: 145px; height: 30px; text-transform: uppercase; font-size: 12px; font-weight: 700;color:$white;text-align:center;
		&:hover {color: $white; background: url("/images/btn-leave.png") no-repeat;}
	}
	&.sm-invite {background: url("/images/sm-invite.png") no-repeat;width: 140px; height: 30px; text-transform: uppercase; font-size: 14px; font-weight: 700;color:$black;text-align:center;padding: 9px 10px 2px;
		&:hover {color: $black; background: url("/images/sm-invite.png") no-repeat;}
	}
	&.sm-cancel {background: url("/images/sm-cancel.png") no-repeat;width: 130px; height: 30px; text-transform: uppercase; font-size: 14px; font-weight: 700;color:$white;text-align:center;
		&:hover {color: $white; background: url("/images/sm-cancel.png") no-repeat;}
	}
} 

.list-arrow {@include list-arrow($white); }

.form-control.StripeElement {padding-top: 13px !important;}
.disabled-payment {pointer-events: none; opacity: 0.7; filter: blur(2px);}

.btn-custom-all { background:$info; border: 2px solid #AEFF6A; border-radius: 0; display:inline-block; text-decoration:none;transform: skew(-40deg);
	.skew-fix{ display:inline-block; transform: skew(40deg); }
	&:hover{background:$success;}
  }
  .btn-custom-all-two { background:$info; border: 2px solid #AEFF6A; border-radius: 25px; display:inline-block; text-decoration:none; font-size: 18px; padding: 8px 25px;
	.skew-fix{ display:inline-block;}
	&:hover{background:$success;}
  }
/* Custom button css end */

.normal-input {margin: 0px;
	fieldset {display: none;}
	.MuiIconButton-root {color: $white;}
	.MuiInputBase-root {padding-right: 0px;}
	.MuiInputAdornment-root {position: absolute; right: 0px;}
	.form-control {
		&::placeholder {color: darken($white, 30%);}
	}
}

.dropdown-notification {
    .scrolldiv .request {max-height: 220px; overflow-x: hidden; overflow-y: auto;}
    .dropdown-toggle {cursor: pointer; position: relative;
		&:after {content: none;}
		img {transform: scale(1.4);}
		.badge {position: absolute; padding: 0.50em 0.6em; top: 0px; right: 0px; border-radius: 10px;}
	}
    .dropdown-menu {min-width: 330px; margin-top: 10px !important; border-radius: 2px; border: none; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12); left: inherit !important; right: 0 !important; position: absolute;
        .dropdown-arrow {position: absolute; bottom:100%; left: inherit; right: 0; overflow: hidden; height:22px; width:50px;
            &:after {content:''; transform: rotate(45deg); background: $white; position: absolute; top: 16px; right: 15px; width: 25px;height: 25px; border-radius: .25rem; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);}
        }
        .dropdown-header {border-bottom: 1px solid rgba(0,0,0,.1); padding: 0px 0px 6px; line-height: 22px; color: #141414;
			.font-18 {font-size: 15px;}
			.text-muted {color: #141414 !important;}
		}
		.notify-loading {text-align: center; padding: 20px 0px;}
    }
    .timeline {position: relative; padding: 0; list-style: none; padding-left: 0px;
        .list-group {
			.list-group-item {padding: 10px; cursor: pointer; line-height: 20px;
				&:first-child {border-top: none;}
				&:hover {background-color: lighten($gray-300, 7%) !important;}
				.image {margin-right: 6px; width: 40px; float: left;
					img {height: 40px; width: 40px; object-fit: cover; object-position: center;}
				}
				.caption {width: calc(100% - 0px); font-size: 14px}
				.text {font-size: 13px; font-weight: 500; color: #212529;}
			}
		}
	}
	.scroller {max-height: 200px; min-height: 280px;}
    .scroll {height: 100%; max-height: 280px; overflow: auto;}
    @media(max-width: 600px) {
      .dropdown-menu {min-width: 310px; right: -133px;
        .dropdown-arrow {right: 128px;}
      }
	}
	.list-group {border-radius: 0px;}
} // Notification dropdown css end


.list-icon {list-style: none; padding-left: 20px; line-height: 50px; font-size: 15px;
	li {padding-left: 0px; width: 50%; float: left; color: $white;
		&:before {content: url("/images/svg/list-con.svg"); padding-right: 10px;}
	}
	@media (max-width: 767px) {
		li {width: 100%;}
	}
} /* List icon css end */

.dashboard-layout {background-color: $body-bg;display: flex;
	#left-side {width: 200px; float: left;margin-top: 170px;margin-bottom:30px; color: $white;border: 1px solid darken($gray-100, 80%);position: relative; transition: all 0.3s ease-in-out;
		.side-inner {position: relative;width: 200px;}
		
		.aside-menu {list-style: none; margin: 0px; padding: 0px;
			> li {
				> a {display: flex; align-items: center; padding: 15px 15px; border-bottom: 1px solid darken($gray-100, 80%); border-left: 2px solid transparent; text-transform: capitalize; color: #B5B5B5;background-color: $secondary;
					&:hover, &.active {text-decoration: none; background-color: #080D18; border-left-color: transparent;color:$info;
						.img {width: 25px; margin-right: 25px;color:$info;
							img {width: 100%;color:$info;}
						}
					}
					.img {width: 25px; margin-right: 25px;
						img {width: 100%}
						i {font-size: 25px;}
					}
				}
			}
		}
	}
	#right-side {width: calc(100% - 200px); float: left; padding: 40px 20px 30px 30px; transition: all 0.3s ease-in-out;}
	&.toggle-layout {transition: all 0.3s ease-in-out;
		#left-side {width: 73px; overflow: hidden;
			.heading {height: 0px; padding: 0px;
				> span {opacity: 0;}
			}
			.profile {//width: 73px; 
				//.username {display: none;}
				.btn-toggle {top: 20px; right: 184px;outline:none;box-shadow:none;}
			}
		}
		#right-side {width: calc(100% - 73px);}
	}
	@media (max-width: 600px) {display: block;
		#left-side {display: block; width: 100% !important; overflow: scroll !important; margin-top: 25px;
			.side-inner {width: 100%;}
			.navbar-toggler {display: block; position: fixed; right: 5px; z-index: 999999; top: 67px; background-color: #000; color: white;
			}
			.btn-toggle {display: none;}
		}
		#right-side {display: block; width: 100% !important; padding: 0px;}
		&.mobile-active {
			#left-side {left: 0px;
			}
		}
	}
} // Dashboard layout css end

.confirm-dialog {
	.MuiDialog-paperWidthSm {max-width: 400px; border-radius: 12px;}
	.MuiDialogTitle-root {padding: 10px 20px;}
	.MuiDialogContent-root {padding: 8px 20px;}
	.MuiDialogContentText-root {margin-bottom: 0px; font-weight: 600; color: $black;}
	.MuiTypography-h6 {font-weight: 600;}
	.btn {border-radius: 8px; font-size: 15px; padding: 6px 20px; font-weight: 500;}
	.closeButton {position: absolute; right: 5px; top: 8px;}
	.MuiDialog-paperWidthSm {min-width: 400px;}
} // Confirm Dialog css end

.highlite-box {margin-bottom: 40px;	
	.image.hover {border: 5px solid transparent; transition: all 0.3s ease-in-out; border-radius: 4px;
		&:hover {border-color: $white;}
	}
	.caption {background-color: #080D18; padding: 10px;}
	.category {color: $white; font-size: 14px; margin-bottom: 8px;
		i {transform: scale(1.4); margin-left: 10px; color: $white;}
		b {color: $white;}
		img {margin-left: 10px;}
		@media (max-width: 600px) {flex-direction: column;}

	}
	.category {
        .regionflage {float: left; font-size: 36px; line-height: 30px;
            img {height: 20px;}
        }
    }
	.title {margin-bottom: 12px;
		h3 {color: $white; font-size: 20px;
			a {color: inherit;}
			a:hover {color: $warning; text-decoration: none;}
		}
	}
	.text {color: #9198A8; font-size: 14px; margin-bottom: 15px;
		a {color: $success;}
		p {margin-bottom: 10px; display: flex;
			span {
				&:first-child {min-width: 125px;}
			}
		}
	}
	.news{font-size: 14px;color:$white;margin: 20px 0px;}
	// .action {margin-bottom: -23px;}
	@media (max-width: 991px) and(min-width: 768px) {
		.text {
			p {display: block;
				span {
				&:first-child {min-width: 100%; display: block;}
			}
			}
		}
	}
}  /* Hightlited box css end */

.loader-file {position: relative;
	.loader {position: absolute; top: 10px; right: 10px; z-index: 1;}
}
.image-thumb {position: relative; margin-bottom: 10px; width: 100px; height: 100px;
	.image {display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; border: 1px solid $gray-700; overflow: hidden;}
	.loader {position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; background-color: rgba($white, 0.8); display: flex; align-items: center; justify-content: center;
		.spinner-border {color: $black;}
	}
	& + .image-thumb {margin-left: 10px;}
} // Thumb image css end

.logo-list {
	ul {list-style: none; padding: 0px;
		li {width: 99px; padding: 15px;}
	}
} // Choose logo css end

.custom-control-input {width: 1.2rem; height: 1.75rem;}
.custom-control {min-height: 1.8rem; padding-left: 1.8rem; line-height: 1.9rem; }
.custom-control-label:before {left: -1.7rem; width: 1.2rem; height: 1.2rem; background-color: transparent; border: $warning solid 1px;}
.custom-control-label:after {left: -1.7rem; width: 1.2rem; height: 1.2rem;}
.custom-control-input:checked ~ .custom-control-label:before {border-color: $warning; background-color: $warning;}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%238C8C8C' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");}
.btn-view {color: darken($white, 30%);padding:0px;
	i{font-size: 19px; color: #9198A8;
		&:hover {color:$success; }
	}
}

label{color: darken($white, 30%);}

.top-search {position: fixed; z-index: 999; background-color: #020E2A; width: 100%; height: 100vh;
	.btn-close {position: absolute;right: 10px;top: -100px;}
	.input-group {
		.MuiFormLabel-root{color: $text;}
		.MuiInput-underline:before {border-color: $text;}
		.MuiInputBase-input {color: white;}
		.input-group-prepend {position: absolute;right: 0;}
	}
}


